<template>
<div class="LoginFormNotify">
  
  <a-form
    ref="form"
    :model="formState"
    :rules="formRules"
    layout="vertical"
    validateTrigger="onSubmit"
    :hideRequiredMark="true"
    @finish="onSubmit"
  >
  
    <a-form-item
      class="ant-form-item-primary"
      name="form_notify"
      :label="formSetup.notify.label"
    >
      <a-select
        v-model:value="form_notify"
        :options="notify"
        :disabled="loading"
        @change="onChange"
        size="large"
      />
    </a-form-item>
    
    <a-button
      class="ant-btn-huge LoginFormPatient__submit"
      type="green"
      size="large"
      html-type="submit"
      :loading="loading"
    >
      Next
    </a-button>
    
  </a-form>
  
</div>
</template>

<script>
import { isSuccess } from '@/helpers/http.helper'
import { mapFields } from '@/helpers/form.helper'
import { mapMutations, mapGetters, mapActions } from "vuex"
import { toast } from '@/helpers/error.helper'
import notify from '@/vocabulary/notify.options.json'

let serverErr, formSetup = {
  notify: {
    label: 'Notification type',
    rules: [
      { required: true, message: 'Please enter notification type' },
      { validator: async () => Promise[serverErr?.notify?.length ? 'reject' : 'resolve'](serverErr?.notify?.[0]) },
    ]
  },
}

export default {
  name: "LoginFormPatient",
  data() {
    return {
      loading: false,
      formSetup,
      notify,
    }
  },
  computed: {
    ...mapGetters({
      patient: 'PatientPatientStore/getPatient',
    }),
    ...mapFields({
      formSetup,
      props: ['fields', 'formState', 'formRules'],
      getter: (vm, field) => vm.patient[field],
      setter: (vm, field, value) => vm.setPatientField({ field, value}),
    }),
  },
  methods: {
    ...mapActions({
      updatePatient: 'PatientPatientStore/update',
    }),
    ...mapMutations({
      setPatient: 'PatientPatientStore/setPatient',
      setPatientField: 'PatientPatientStore/setPatientField',
    }),
    onChange() {
      this.$refs.form.clearValidate()
      serverErr = null
    },
    async onSubmit() {
      let payload = this.patient.toApi()
      this.loading = true
      await this.updatePatient({ payload })
        .then((response) => {
          if (response instanceof Error || !isSuccess(response.status)) throw response
          toast ({ type: 'success', msg: 'Success' })
          this.setPatient(this.patient)
          this.$router.push({ name: 'patient.confirm.consent' })
        })
        .catch((err) => {
          serverErr = err.response?.data?.errors
          toast ({ msg: err?.data?.data?.message })
          this.$refs.form.validateFields()
        })
        .finally(() => {
          this.loading = false
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.LoginFormNotify {
  padding: 24px 0;
  
  &__date {
    width: 100%;
  }
  
  &__submit {
    margin-top: 10px;
  }
  
}
</style>